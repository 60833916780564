import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "aktivitas";
const modelPath = `/${modelName}/`;

class Aktivitas extends ModelBase {
  constructor() {
    let model = {
        id: null,
        preceptor: null,
        tanggal: null,
        kompetensi: "",
        jenis_keterampilan: null,
        virtual: false
    };
    let requiredFields = ["preceptor", "tanggal", "kompetensi", "jenis_keterampilan"];
    super(modelName, model, requiredFields, [], modelPath);
  }

  getLoadData(data) {
    let tdata = super.getLoadData(data);
    tdata.preceptor = tdata.preceptor.id;
    tdata.jenisKet = tdata.jenis_keterampilan.nama;
    tdata.jenis_keterampilan = tdata.jenis_keterampilan? tdata.jenis_keterampilan.id : null;
    return tdata;
  }

  // getPayload() {
  //   let data = super.getPayload();
  //   delete data.jenisKet;
  //   return data;
  // }
}

export default Aktivitas;