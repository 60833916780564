<template>
  <div class="columns no-margin">
    <div class="column no-padding is-full">
      <div class="card">
        <header class="card-header has-background-primary">
          <p class="card-header-title has-text-light">{{ headerText }}</p>
        </header>
        <div class="card-content events-card">
          <b-field class="field-spacer"></b-field>
          <template v-if="errorMap.non_field_errors">
            <p class="help is-danger">{{ errorMap.non_field_errors }}</p>
            <b-field class="field-spacer"></b-field>
          </template>
          <b-field
            v-if="jenis == 'inap' || jenis == 'tanpa_pasien'"
            label="Tanggal"
            custom-class="is-small"
            :type="{'is-danger': errorMap.tanggal}"
            :message="errorMap.tanggal"
          >
            <date-picker v-model="aktivitas.tanggal" @input="validateInput('tanggal')"></date-picker>
          </b-field>

          <b-field
            label="Preceptor"
            custom-class="is-small"
            :type="{'is-danger': errorMap.preceptor}"
            :message="errorMap.preceptor"
          >
            <generic-autocomplete
              apiPath="/anggota/preceptor/"
              :params="preceptorOptionsParams"
              v-model="aktivitas.preceptor"
              @input="validateInput('preceptor')"
            ></generic-autocomplete>
          </b-field>
          <template v-if="!aktivitas.virtual">
            <b-field
              label="Kompetensi"
              custom-class="is-small"
              :type="{'is-danger': errorMap.kompetensi}"
              :message="errorMap.kompetensi"
            >
              <b-select
                v-model="aktivitas.kompetensi"
                placeholder="Kompetensi"
                required
                expanded
                @input="validateInput('kompetensi')"
              >
                <option
                  v-for="kompetensi in kompetensiOptions"
                  :value="kompetensi"
                  :key="kompetensi"
                >{{ kompetensi }}</option>
              </b-select>
            </b-field>
            <b-field
              label="Jenis Keterampilan"
              custom-class="is-small"
              :type="{'is-danger': errorMap.jenis_keterampilan}"
              :message="errorMap.jenis_keterampilan"
            >
              <generic-select
                v-model="aktivitas.jenis_keterampilan"
                :apiPath="jenisOptionsUrl"
                @input="validateInput('jenis_keterampilan')"
              ></generic-select>
            </b-field>
          </template>
          <b-field class="field-spacer"></b-field>
          <div class="buttons">
            <b-button class="is-primary has-text-weight-semibold" @click.stop="cancel">Batal</b-button>
            <b-button
              class="is-primary has-text-weight-semibold"
              :disabled="!isValid"
              @click.stop.prevent="saveObject"
            >Simpan</b-button>
          </div>
          <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState } from "vuex";
import createUpdateMixin from "@/apps/core/mixins/createUpdateMixin";
import Aktivitas from "../models/cuAktivitas.js";

export default {
  name: "AktivitasCreateUpdate",
  props: ["headerText", "jenis"],
  mixins: [createUpdateMixin],
  components: {
    GenericAutocomplete: () =>
      import("@/apps/core/components/GenericAutocomplete.vue"),
    GenericSelect: () => import("@/apps/core/components/GenericSelect.vue"),
    DatePicker: () => import("@/apps/core/components/DatePicker.vue"),
  },
  data() {
    this.aktivitasMdl = new Aktivitas();
    this.objectMdl = this.aktivitasMdl; // alias yg digunakan di cuMixin
    this.kompetensiOptions = ["Observasi", "Operator dengan Supervisi"];
    let obv = this.aktivitasMdl.getObservables();
    return obv;
  },
  computed: {
    ...mapState("accounts", ["userId"]),
    preceptorOptionsParams() {
      if (!this.isCreate && this.isLoaded) return {sub_departemen: this.aktivitas.sub_departemen.id};
      return {};
    },
    jenisOptionsUrl() {
      let url = '/aktivitas/keterampilan/jenis/';
      if (this.jenis == 'tanpa_pasien' && !this.aktivitas.virtual) {
        url += '?jenis=offline_tanpa_pasien';
      }
      return url;
    }
    // preceptorOptionsUrl() {
    //   let url = `/anggota/preceptor/`;
    //   if (this.isCreate) {
    //     return url;
    //   } else if (this.isLoaded) {
    //     return `${url}?sub_departemen=${this.aktivitas.sub_departemen.id}`;
    //   }
    //   return "";
    // },
  },
  methods: {
    fetchData() {
      if (this.jenis == "jalan") delete this.errorMap["tanggal"];
      if (this.isCreate) {
        this.aktivitasMdl.addPayloadData({
          pspd: this.userId,
          perawatan: this.$route.params.perawatanId,
        });
      } else {
        this.objectMdl.load(this.$route.params.id);

        if (this.jenis != "virtual") return;
        this.objectMdl.setRequiredFields(["tanggal", "preceptor"]);
        delete this.errorMap["kompetensi"];
        delete this.errorMap["jenis_keterampilan"];
      }
    },
    saveObject() {
      // if (this.isCreate) {
      //   this.aktivitas.pspd = this.userId;
      //   this.aktivitas.perawatan = this.$route.params.perawatanId;
      // }
      const params = { jenis_perawatan: this.jenis };
      this.aktivitasMdl.save(this.saveContext, this.onSaved, params);
    },
  },
  watch: {
    aktivitas: {
      // aktivitas berubah, update edited
      handler(newValue, oldValue) {
        if (!this.aktivitasMdl.getEdited()) {
          this.setEdited(this.isCreate || !!oldValue.id);
        }
      },
      deep: true,
    },
  },
};
</script>
